<template>
  <div class="map-dynamic-filter">
    <condition
      v-model="localCondition"
      :conditions="conditionOptions"
      @change="changeCondition"
      class="dynamic-filter-condition"
    ></condition>
    <el-input
      v-model="filter.value"
      size="mini"
      class="dynamic-filter-value"
      :disabled="['is_null', 'is_not_null'].includes(filter.condition)"></el-input>
  </div>
</template>

<script>
  import Condition from './Conditions'

  export default {
    name: 'AddressFilter',
    components: { Condition },
    props: {
      filter: {
        type: Object,
        required: true
      }
    },
    data: function () {
      return {
        conditionOptions: ['eq', 'neq', 'like', 'not_like', 'is_null', 'is_not_null'],
        localCondition: null
      }
    },
    mounted () {
      this.$set(this, 'localCondition', this.filter.condition)
    },
    methods: {
      changeCondition (newVal) {
        this.$set(this.filter, 'condition', newVal)
      }
    }
  }
</script>

<style scoped>

</style>
